.pr-resources-tab {
    color: #FFFFFF;
    font-family: 'iCiel Gotham Medium';
    font-size: 14px;
    letter-spacing: 0.5px;
    height: 250px;
    width: 100%;
    padding: 0px 10px !important;
    border-radius: 15px;
}
    .hover {
        background-color: rgba(#F5A26C, 1);
        color: white;
        width: 100%;
        height: 100%;
        text-align: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 2em;
        border-radius: 15px;
        cursor: pointer;
    }

    .item {
        border-radius: 15px;
    }