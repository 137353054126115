.news-content {
  font-family: 'Calibri Regular';
  p {
    font-size: 16px;
    margin-bottom: 1em;
    letter-spacing: 0.5px;
  }
}

.news-detail-container {
  .title {
    font-family: 'Calibri Bold';
    line-height: 1.5;
    margin-bottom: 1em;
    font-size: 22px;
    margin-top: 0;
  }
}

.n-year-btn {
  font-family: 'Calibri Regular';
  font-size: 18px !important;
  color: #f08a54 !important;
  background: none;
  letter-spacing: 0.5px;
  margin-top: 10px;
  margin-bottom: 10px !important;

}

*:focus {
  outline: 0 !important;
  outline:none !important;
  box-shadow: none !important;
}

.news-go-back {
  margin-top: 80px;
}
  .news-go-back a {
    font-family: 'Calibri Regular';
    font-size: 15px !important;
    color: #f08a54 !important;
    letter-spacing: 0.3px;
  }

  .news-go-back:hover {
    opacity: 0.5;
  }

  .go-back-btn {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    width: 20px;
    margin-right: 10px;
    margin-top: -2px;
  }