.grey-arch-bckg {
    position: absolute;
    top: 120px;
    left: 0;
    background-image: url('../../images/top-curve.svg');
    background-color: #fff;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 300%;
    color: $primary;
    width: 100%;
    height: 300px;
    z-index: -100;
  }

    .step-title {
        margin-top: 80px !important;
        font-family: 'Calibri Bold';
        color: #3D3D3B;
        font-size: 21px;
        letter-spacing: 0.5px;
        line-height: 1.3;
    }
        .extra-padding {
            padding-top: 8px;
        }
        
        .tick {
            width: 8%;
            margin-left: 60px;
            margin-top: -2px;
        }
            .tick-sm {
                display: none;
            }

        .clipboard-img {
            width: 60%;
            margin-top: 80px;
            margin-left: 200px;
        }

    .arrow {
        width: 3%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .product-img {
        width: 110% !important;
    }

    .product-scanner {
        margin-left: -130px;
        margin-top: 50px;
    }

    .step-title-3 {
        margin-top: 100px;
        font-family: 'Calibri Bold';
        color: #3D3D3B;
        font-size: 21px;
        letter-spacing: 0.5px;
        line-height: 1.3;
    }
        .computer {
            width: 180%;
            margin-left: -30%;
        }
        .computer-sm {
            display: none;
        }

//----------- Responsiveness ----------- //
@media (max-width: 1199px) {
    .step-title {
      margin-top: 200px;
    }
    .product-scanner {
        margin-left: -90px;
    }
  }

@media (max-width: 991px) {
    .tick {
      margin-left: 0px;
      width: 11%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .step-title {
        margin-top: 100px;
        font-size: 17px;
        text-align: center;
    }
    .clipboard-img {
        margin-left: 130px;
        margin-top: 50px;
    }

  }

@media (max-width: 767px) {
    .grey-arch-bckg {
        margin-top: 0px;
        top: 0;
    }
    .product-scanner {
        display: none;
    }
        .scanner-sm {
        display: block;
        width: 90%;
        margin-left: 0px;
        } 

    .computer {
    width: 120%;
    margin-left: -0%;
    }
    .arrow {
        width: 5%;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .clipboard-img {
        margin-left: 320px;
        margin-top: -32px;
        width: 45%;
    }
    .step-title {
        margin-top: 40px;
        text-align: center;
    }
    .tick {
        display: none;
    }
        .tick-sm {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 9%;
            margin-top: 20px;
        }
    .step-title-3 {
        margin-top: 0px !important;
    }
    .computer {
        display: none;
    }
        .computer-sm {
            display: block;
            width: 100%;
        }
}

@media (max-width: 680px) {
    .product-img {
        width: 100% !important;
    }
    .computer {
        width: 100%;
    }
    .clipboard-img {
        margin-left: 0px;
        float: right;
    }
}

@media (max-width: 550px) {
    .product-img {
        width: 100% !important;
    }
    .computer {
        width: 100%;
    }
    .clipboard-img {
        margin-left: 0px;
        float: right;
    }
    .arrow {
        width: 7%;
    }
    .tick-sm {
        width: 11%;
    }
}

@media (max-width: 550px) {
    .step-title {
        font-size: 20px;
    }
}

@media (max-width: 450px) {
    .tick-sm {
        width: 14%;
    }
}