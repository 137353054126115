.collapse-container {
  .button {
    font-family: 'Calibri Regular';
    color: $primary;
    padding: 0 1em;
    letter-spacing: 0.5px;
    margin-top: 0px;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
    .collapse in {
      .row {
        padding: 20px 20px 40px 20px;
        .col-md-5 {
          .background {
            height: 350px;
            margin-left: 0px;
            margin-bottom: 30px;
          }
        }
      }
    }
    .arrow {
      height: 10px;
      float: right;
      transition: 0.6s;
      margin-top: 5px;
    }
    &[aria-expanded='true'] {
      .arrow {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
  hr {
    border-top: 1px solid rgb(224, 222, 222);
  }
  .collapse-padding {
    padding: 20px 20px 40px 20px;
  }
}
