.contact-container {
  position: relative;
  min-height: 20em;

  h1 {
    font-family: 'Calibri Bold';
    color: $blue;
    font-size: 26px;
  }

  .contact-information {
    font-family: 'Calibri Light';
    font-size: 15px;
    margin-left: 80px;
    .contact-info-title {
      font-family: 'Calibri';
      color: #f08a54;
      font-size: 19px;
      letter-spacing: 0.5px;
      margin-bottom: 20px;
    }
    p {
      margin: 0 0 10px;
      letter-spacing: 0.5px;
      font-size: 14px;
      line-height: 1;
    }
    a {
      display: block;
      text-decoration: none;
      margin: 0 0 10px;
      font-family: 'Calibri Light';
      color: $text-color;
      letter-spacing: 0.5px;
      font-size: 14px;
      line-height: 1px;
    }
  
    img {
      width: 2em;
      height: auto;
      margin-right: 5px;
    }
  }

  .contact-form {
    $font-light: 'Calibri Light';
    $font-regular: 'Calibri Regular';
    $text-light-color: '#B2B2B2';
    $form-font-size: 17px;
    letter-spacing: 0.5px;

    margin-top: 24px;
    position: relative;
    font-size: $form-font-size;


    label {
      width: 100px;
      font-family: 'Calibri Regular' !important;
      font-weight: 300;
      color: $text-color;
      font-size: $form-font-size;
      letter-spacing: 0.5px;
    }

    .contact-form-input {
      position: relative;
      width: 100%;
      margin-bottom: 2em;
      display: flex;
      align-items: center;
      letter-spacing: 0.5px;
      
      .input-wrapper {
        width: 100%;
      }

      input {
        font-family: $font-light;
        font-size: $form-font-size;
        letter-spacing: 0.5px;
        height: 40px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        outline: none;
        color: $text-light-color;
        padding-left: 7px;
        flex-grow: 1;
      }
    
      select {
        font-family: $font-light;
        font-size: $form-font-size;
        letter-spacing: 0.5px;
        width: 100%;
        height: 40px;
        border: none;
        padding-left: 1em;
        padding-right: 1em;
        border-radius: 5px;
        outline: none;
        background-color: #ebebeb;
    
        option {
          background-color: #ffffff;
          &:hover {
            box-shadow: 0 0 10px 100px #fde3ce inset;
            color: $text-color;
          }
        }
      }
    }

    .error {
      position: absolute;
      bottom: -20px;
      color: red;
      font-size: 14px;
      font-family: $font-light;
    }

    textarea {
      width: 100%;
      border: 1px solid #aeaeae;
      font-family: $font-light;
      font-size: $form-font-size;
      border-radius: 5px;
      outline: none;
      resize: none;
      padding-left: 0 15px;
      color: $text-light-color;
    }
  
    .submit-button {
      float: right;
      width: 150px;
      margin-top: 1em;
      background-color: $primary;
      padding: 5px 20px;
      color: white;
      outline: none;
      border: none;
      &:hover {
        background: $primary-hover;
      }
      &:disabled {
        background: lightgray;
      }
    }

    .submit-message {
      font-size: 18px;
      margin-bottom: 1em;
      color: green;
      &.error {
        color: red;
      }
    }
  }
  
  .contact-map {
    margin-bottom: 2em;
    overflow: hidden;
  }

  .contact-gdpr {
    font-family: 'Calibri Light';
    color: $text-color;
    letter-spacing: 0.5px;
    font-size: 13px;
    padding: 50px 0px;
    .gdpr-title {
      font-family: 'Calibri Bold';
      color: #3d3d3b;
      letter-spacing: 1px;
    }
    .click-here {
      text-decoration: none;
      font-family: 'Calibri Bold';
      color: #3d3d3b;
      letter-spacing: 1px;
      &:hover {
        color: $primary-hover;
      }
    }
  }
}

.contact-background {
  position: absolute;
  background-image:url('../../images/top-curve.svg'), url('../../images/swish-gradient.svg');
  background-color: #ffffff;
  background-position: center 0, center 420px;
  background-size: 250%, 150%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: -100;
  margin-top: -70px;
}

//----------- Responsiveness ----------- //
@media (max-width: 991px) {
  .contact-container .contact-information {
    margin-left: 50px;
  }
}

@media (max-width: 767px) {
  .contact-container .contact-information {
    margin-left: 0px;
  }
  .contact-container .contact-form .submit-button {
    width: 100%;
    margin-bottom: 2em;
  }
  .contact-background {
    margin-top: 0px;
    top: 0;
  }
}