
.sab-profile-container {
    margin-top: 25px;
}
    .sab-container .title {
        font-size: 18px;
        margin-top: 15px;
        letter-spacing: 0.5px;
        margin-bottom: 5px;
    }
        .sab-container .sub-title {
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 0px;
            color: #b1b1b1;
            letter-spacing: 0.3px;
            padding-bottom: 15px;
            color: #ffa500;
        }

        .sab-container .bio {
            font-size: 12px !important;
            margin-top: 0px;
            padding-bottom: 50px;
            border-bottom: 1px solid #f08a54;
        }

    .sab-profile-image {
        width: 50%;
        border-radius: 300px;
    }

.sab-board-images {
    width: 70%;
    height: 100%;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
}

.sab-head-paragraph {
    margin-bottom: 70px !important;
    margin-top: 30px;
    font-size: 20px !important;
    line-height: 1.5 !important;
    font-family: 'Calibri Bold' !important;
}