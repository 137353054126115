.home-section {
  min-height: 650px;
  font-family: 'Calibri Bold';
  padding: 130px 0px 0px 0px;

  .hero-title {
    font-family: 'iCiel Gotham Medium';
    font-size: 38px;
    line-height: 1.2;
    margin: 0px;
    letter-spacing: 0.5px;
  }
    .hero-sub-title {
      font-family: 'iCiel Gotham Light';
      font-weight: 0 !important;
      font-size: 29px;
      line-height: 1.2;
      font-weight: 300;
      margin-top: 5px;
      letter-spacing: 0.5px;
    }

  .section-title {
    font-family: 'iCiel Gotham Medium';
    font-size: 36px;
    line-height: 1.25;
    font-weight: 300;
    color: #344e79;
    margin-top: 0px;
    margin-bottom: 30px;
    letter-spacing: 0.8px;
  }
    .section-sub-title {
      font-family: 'iCiel Gotham Light';
      font-size: 13px;
      line-height: 2;
      margin-top: 40px;
      margin-bottom: 40px;
      font-weight: 700;
      letter-spacing: 0.5px;
    }
}

.home-background {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('../../images/banner.png');
  background-color: #ffffff;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  z-index: -100;
}

.home-background-2 {
  background-image: url('../../images/swish.svg');
  background-position: center;
  background-repeat: no-repeat;
  height: 600px;
  z-index: -100;
  margin-top: -300px;
}

.home-hero {
  &__title {
    width: 60%;
    text-align: center;
  }
  .section-title {
    font-size: 38px;
    font-weight: 300;
    color: inherit;
    letter-spacing: 0.5px;
  }
}

.home-content-1 {
  align-items: center;
  padding: 0px !important;
  min-height: 500px !important;
}

.home-notice {
  display: flex;
  justify-content: space-between;
  // background-color: #fcfcfc;
  background-image: linear-gradient(to bottom right, #fff ,#fff, #f1f3fa);
  box-shadow: 1px 1px 10px 1px #dfdfdf;
  border-radius: 10px;
  width: 100%;
  padding: 15px;
  margin-bottom: 25px;
  align-items: center;
  font-family: 'Calibri Light';
      &:hover {
      opacity: 0.5;
    }

.home-links {
  font-family: 'Calibri Light';
}

  a {
    font-size: 15px;
    letter-spacing: 0.5px;
    flex-grow: 1;
    margin-left: 12px;
    margin-bottom: 0px;
    text-decoration: none;
    color: #3D3D3B;
    &:hover {
      opacity: 0.5;
    }
  }
  img {
    height: 50px;
  }
}

.home-content-2 {
  padding: 50px 0px 0px 0px !important;
  .left-content {
    padding-right: 100px;
    img {
      width: 650px;
      margin-left: -130px;
    }
  }
}

.home-content-3 {
  padding-bottom: 200px;
  padding: 40px 0px 0px 0px !important;
  .img {
    padding-left: 20px;
    width: 600px;
  }
  .image-description {
    text-align: center;
    font-family: 'Calibri';
    font-size: 14px;
    justify-content: space-around;
    letter-spacing: 2px;
  }
}

.scanner {
  width: 120%;
  margin-left: -150px;
}
  .scanner-sm {
    display: none;
    margin-bottom: 30px;
  }  

.fai-scan {
  display: block;
} 
  .fai-img {
    width: 100%;
  }
  .fai-scan-sm {
    display: none;
  }

.no-padding {
  padding: 0px !important;
}

.divider-sm {
  display: none;
}

a {
  text-decoration: none !important;
}

//----------- Responsiveness ----------- //
@media (max-width: 991px) {
  .home-notice {
    display: inline-flex;
    margin: 10px;
  }
  .space {
    display: none;
  }
}

@media (max-width: 1290px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}


@media (max-width: 950px) {
  .scanner {
    margin-left: -90px;
  }
}


@media (max-width: 827px) {
  .container {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 767px) {
  .home-section {
    padding: 0px;
    margin-top: -20px;
    min-height: 350px;
  }
    .home-section .hero-sub-title {
      line-height: 1.35;
    }

  .home-background {
    opacity: 0.5;
    margin-top: -200px;
  }
  .home-background-2 {
    display: none;
  }
  .home-hero__title {
    width: 100%;
  }
  .divider-sm {
    display: block;
    margin-top: 60px !important;
    margin-bottom: 40px !important;
  }
  .scanner {
    display: none;
  }
    .scanner-sm {
      display: block;
      width: 90%;
      margin-left: 0px;
    }  
  .fai-scan {
    display: none;
  } 
      .fai-scan-sm {
        display: block;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 10px;
        margin-bottom: 50px;

      }
}

@media (max-width: 660px) {
  .home-notice {
    width: 100%;
  }
}

@media (max-width: 590px) {
  .container {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }  
}

@media (max-width: 375px) {
  .container {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
}


