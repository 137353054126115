.video {
  width: 100%;
  height: 100%;
  img {
    padding: 1em;
    width: 100%;
  }
  .line {
      border-left: 1px solid rgba(0,0,0,.1);
      width: 1px;
      height: 35vw;
  }
  .sub-title {
    color: $primary;
  }
  .caption {
    padding-top: 1em;
    .sub-title {
      margin-top: 1em;
    }
    p {
      margin: 0;
      padding: 0;
    }
    .paragraph {
      font-size: 1em;
    }
  }
}
