.breadcrumb-container {
  display: flex;
  padding-bottom: 3em;
  font-family: 'Calibri Light';
  .breadcrumb-text {
    font-size: 12px;
    letter-spacing: 0.8px;
  }
  .spliter {
    padding: 0 20px;
  }
}

//----------- Responsiveness ----------- //
@media (max-width: 767px) {
  .breadcrumb-container {
    display: none;
  }
}
