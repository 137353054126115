//----------- Shared Styles ----------- //
.paragraph {
  margin-bottom: 2em;
  color: $grey-100;
  font-family: 'iCiel Gotham Light';
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 2;
  font-weight: 700;
}

.professionals-container {
  position: relative;

  h2 h3 h4 {
    font-family: 'Calibri Bold';
    letter-spacing: 0.5px;
  }

  .chart-caption {
    font-size: 13px;
    font-family: 'iCiel Gotham Medium';
    text-align: center;
    margin-top: 20px;
    letter-spacing: 0.5px;
  }

  .graph-title {
    color: #3d3d3b;
    font-weight: 700;
  }

  .text-bold {
    font-family: 'Calibri Bold';
    font-size: 15px !important;
    line-height: 1.3;
  }

  .step-content {
    padding: 25px 30px 100px 30px;
    border-top: 1px solid #e0dede;
    border-bottom: 1px solid #e0dede;
  }

  .step-title {
    margin-bottom: 3px !important;
    font-size: 18px;
    line-height: 1.5;
    font-family: 'Calibri Bold';
    letter-spacing: 0.5px;
  }

  .content1 {
    .section1-image {
      position: absolute;
      width: 200px;
      right: 0;
      bottom: -50px;
    }
  }

  //--- Step Navigation
  .content2 {
    .clinical-evidence-step {
      margin: 100px 80px 80px 80px;
      height: 386px;
      cursor: pointer;
      width: 100%;
      .steps-arrow {
        width: 0.7em;
        height: 6em;
        object-fit: cover;
      }
      h5 {
        text-align: center;
        line-height: inherit;
        padding: 10px 10px;
        font-family: Calibri Regular;
      }
    }
  }
    .content2 {
      .sm-clinical-evidence-step {
        margin: 100px 0px 80px 0px;
        height: 271px;
        cursor: pointer;
        width: 100%;
        .steps-arrow {
          width: 0.7em;
          height: 6em;
          object-fit: cover;
        }
        h5 {
          text-align: center;
          line-height: inherit;
          padding: 10px 10px;
          font-family: 'Calibri Regular';
          letter-spacing: 0.5px;
        }
      }
    }
  .steps-text {
    position: absolute;
  }
    .steps-text-1 {
      bottom: 180px;
      left: 55%;
      width: 60%;
    }
    .steps-text-2 {
      bottom: 220px;
      left: 32%;
      width: 75%
    }
    .steps-text-3 {
      bottom: 270px;
      padding: 0px 15px;
      left: 15%;
    }
    .steps-text-4 {
      bottom: 320px;
      width: 90%;
      right: 10%;
    }


 
  .content3 {
    .hover-to-display {
      position: absolute;
      display: none;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 2em;
      background-color: rgba($primary, 0.9);
      color: white;
      justify-content: space-between;
      flex-direction: column;
      letter-spacing: 0.5px;
      font-size: 14px;
      font-family: 'Calibri Bold';
      
      .text1 {
        text-align: center;
        margin-bottom: 1.5em;
      }

      .primary-endpoint {
        line-height: 2;
        font-size: 14px;
      }
      .text2 {
        font-family: 'Calibri Regular';
        font-size: 12px;
        letter-spacing: 0.5px;
      }
    }
  }

  .content4 {
    margin-top: 90px;
  }

//--- Page 1  
  .step1 {
    .chart-content {
      margin-top: 80px;
      .img {
        padding: 10px;
        width: 100%;
      }
    }
  }


//--- Page 2  
  .step2 {
    .content-header {
      margin-bottom: 50px;
    }

    .step-title {
      margin-bottom: 3px !important;
    font-size: 18px;
    line-height: 1.5;
    font-family: 'Calibri Bold';
    }

    .sub-text {
      font-family: 'Calibri';
      font-size: 13px;
      margin: 0;
    }

    .video-container {
      max-width: 600px;
      margin: 20px auto;
      margin-bottom: 100px;
      video {
        padding: 30px 0;
      }
    }

    .mes {
      margin-top: 100px;
      display: flex;
      justify-content: center;

      .header {
        font-family: 'Calibri Regular';
        font-size: 15px;
        letter-spacing: 1px;

        &.header1 {
          color: #575756;
        }
        &.header2 {
          background-color: #fccb8d;
          color: #fff;
        }
      }

      .side-left {
        font-family: 'Calibri';   
        transform-origin: bottom;
        color: #575756;
        
        &__title {
          position: absolute;
          letter-spacing: 2px;
          font-size: 15px;
          height: 60px;
          line-height: 1;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .side-left-3 {
          width: 15em;
          height: 3em;
          padding: 0.5em;
          text-align: center;
          background-color: #ebeff9;

          + .img {
            top: 1em;
            left: 0em;
            transform: rotate(90deg);
            &.img-1 {
              position: relative;
              top: 2em;
            }
          }
        }
      }

      .side-right {
        font-family: 'Calibri Regular';
        line-height: 2em;
        background-color: rgba($primary, 0.1);
        border: 1px solid rgba($primary, 0.5);
        color: #575756;
        padding-bottom: 35px;

        .graph {
          height: 15em;
        }
      }
    }
  }

  .page2-diagram-lg {
    display: block;
  }
    .margin-side-right {
      margin-left: -95px;
    }

    .page2-side-row {
      width: 88%;
      transform: translate(-50%, 120px) rotate(-90deg);
      position: absolute;
      margin-top: 79px;
      margin-left: 135px;
      font-family: 'Calibri Light';
      letter-spacing: 0.8px;
    }

      .page2-side-row-end {
        width: 88%;
        transform: translate(-50%, 120px) rotate(-90deg);
        position: absolute;
        margin-top: -650px;
        margin-left: 902px;
        font-family: 'Calibri Light';
        letter-spacing: 0.8px;
      }
        .page2-side-title-1 {
          text-align: center;
          background-color: #ebeff9;
          padding: 10px;
          margin-right: 10px;
        }
        .page2-side-title-1-border {
          border-top: 6px solid #ebeff9;
          margin-right: 10px;
        }

        .page2-side-title-2 {
          text-align: center;
          background-color: #fffadb;
          padding: 20px 10px 10px 10px;
        }    
        .page2-side-title-2-border {
          border-top: 6px solid #fffadb;
        }

    .top-left-title {
      text-align: center;
      font-family: 'Calibri Light';
      letter-spacing: 0.8px;
      width: 80%;
      margin: 5px 0px;
      padding: 3px 0px;
      color: #000000;
      font-size: 15px;
    }

    .top-right-title {
      text-align: center;
      font-family: 'Calibri Light';
      letter-spacing: 0.8px;
      width: 100%;
      margin: 5px 0px;
      padding: 3px 0px;
      background: #ffb456;
      color: #FFFFFF;
      font-size: 15px;
    }
      .page2-images {
        width: 100%;
        margin: 15px 0px;
      }

      .page2-diagram-text {
        margin-top: 20px;
        margin-left: -10px;
        font-size: 11px;
        font-family: 'Calibri Regular';
        letter-spacing: 1px;
        line-height: 1.5;
      }

.page2-diagram-sm {
  display: none;
}

.diagram-sm-healthy-container {
  margin-bottom: 50px;
}
  .healthy-section-2 {
    margin-top: 54px !important;
  }

  .diagram-sm-header {
    text-align: center;
    font-family: 'Calibri Light';
    letter-spacing: 0.8px;
    font-size: 15px;
    height: 35px;
    padding-top: 7px;
  }
      .header-healthy {
        border-bottom: 1px solid #e0dede;
        color: #000000;
      }

      .header-inflamed {
        background: #ffb456;
        color: #FFFFFF;
      }

        .diagram-sm-page2-side-row {
          transform: translate(-50%, 120px) rotate(-90deg);
          margin-top: 176px;
          margin-left: 15px;
          width: 151%;
        }
        
        .side-row-width {
          transform: translate(-50%, 120px) rotate(-90deg);
          margin-top: 176px;
          margin-left: 15px;
          width: 151% !important;
        }
          .diagram-sm-left-title-1 {
            text-align: center;
            background-color: #ebeff9;
            border: 2px solid #FFFFFF;
            padding: 10px;
            margin-right: 10px;
          }
          .diagram-sm-left-title-2 {
            text-align: center;
            background-color: #fffadb;
            border: 2px solid #FFFFFF;
            padding: 10px;
          }

          .diagram-sm-page2-side-row-border {
            transform: translate(-50%, 120px) rotate(-90deg);
            margin-top: 176px;
            margin-left: -16px;
            width: 151%;
          }
            .diagram-sm-page2-title-1-border {
              border-top: 6px solid #ebeff9;
              margin-right: 10px;
            }  
            .diagram-sm-page2-title-2-border {
              border-top: 6px solid #fffadb;
            }

        .diagram-sm-healthy {
          padding: 10px;
          margin-top: 10px;
          border: 2px solid #f0f4ff;
        }

        .diagram-sm-inflamed {
          padding: 10px;
          margin-top: 10px;
          background-color: rgba(240,138,84,.1);
          border: 1px solid rgba(240,138,84,.5);
        }

        .sm-fai-image {
          width: 80%;
          padding-bottom: 30px;
        }

        .sm-page2-diagram-text {
          margin-top: 0px !important;
          font-size: 13px !important;
          line-height: 1.7 !important;
        }

  //--- Page 3 
  .step3 {
    .row {
      padding: 2em;
    }
    p {
      strong {
        font-family: 'iCiel Gotham Medium';
        color: black;
        font-weight: 200;
      }
    }
  }

  //--- Page 4   
  .clinical-evidence-list {
    padding-left: 25px !important;
    margin-top: -13px;
  }
  ::marker {
    color: #e86d03;
  }
  .step4 {
    .row {
      padding: 40px 0px 10px 0px;
    }
    .image-section-1 {
      padding: 20px 0px 80px 0px;
      .images {
        display: flex;
        align-items: center;
      }
    }
    .diagram {
      padding: 4em;
    }
    h5 {
      padding-top: 2em;
      text-align: center;
      font-size: 12px;
      color: #575756;
      letter-spacing: 1px;
      font-family: 'iCiel Gotham Medium';
      letter-spacing: 0.5px !important;
      line-height: 1.3;
      strong {
        color: $primary;
      }
    }

    .figure-caption {
      font-family: 'Calibri';
      display: flex;
      padding: 1em 0;
      align-items: flex-end;
      .figure-item {
        display: flex;
        align-items: flex-start;
        margin-right: 2em;
      }
      img {
        width: 20px;
        margin-right: 10px;
      }
      .low-risk {
        color: #acbf69;
      }
      .intermediate-risk {
        color: #f9ca9a;
      }
      .high-risk {
        color: #f4a58d;
      }
    }

    .diagram1 {
      display: flex;
      .diagram-caption {
        font-family: 'Calibri Regular';
        width: 100px;
        text-align: left;
        padding-bottom: 40px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: center;
        letter-spacing: 2px;
        font-size: 13px;
        .diagram-caption-1 {
          padding-top: 290px;
        }
        .diagram-caption-2 {
          padding-top: 105px;
        }
      }
      .diagram-image {
        width: 100%;
        margin-top: 70px;
        img {
          width: 100%;
        }
      }
    }

    .diagram-link:hover {
      opacity: 0.5;
    }

    .image-link-hover:hover {
      opacity: 0.5;
    }

    .section2 {
      padding-top: 5em;

      .score {
        .header {
          padding: 1px;
          background-color: #EAECF7;
          text-align: center;
          h4 {
            font-size: 13px;
            font-family: 'Calibri Regular';
            letter-spacing: 0.5px;
          }
        }
        .body {
          margin-top: 1em;
          height: inherit;
          border: 1px solid #EAECF7;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 4em;
          h4 {
            width: 60%;
            font-family: 'Calibri Bold';
          }
          img {
            width: 20em;
            padding-bottom: 4em;
          }
        }
      }
    }
  }

  .orange-header {
    background-color: #fcddc7 !important
  }
    .arrow-container {
      margin-top: 93px;
    }

    .graph-container {
      margin-top: 43px;
    }

  &.education {
    .videos-section {
      margin-top: 150px;
    }
    .video-item {
      margin-bottom: 100px;
      .date {
        font-family: 'Calibri Regular';
        font-size: 15px;
        color: $primary;
        margin-bottom: 0.5em;
        letter-spacing: 0.5px;
        margin-bottom: 30px;
      }
      .title {
        font-family: 'Calibri Bold';
        font-size: 16px;
        margin-bottom: 1em;
        letter-spacing: 0.5px;
      }
      .thumbnail {
        img {
          width: 100%;
        }
      }
      .description {
        font-family: 'Calibri';
        font-size: 13px;
        color: $text-color;
        font-weight: 100;
        letter-spacing: 0.5px;
      }
    }
  }
}

.professional-background-1 {
  position: absolute;
  top: 120px;
  left: 0;
  background-image: url('../../images/top-curve.svg');
  background-color: #fff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 300%;
  color: $primary;
  width: 100%;
  height: 300px;
  z-index: -100;
}

.education-background-2 {
  position: absolute;
  top: 300px;
  left: 0;
  background-image: url('../../images/orange_swish.svg');
  background-color: #fff;
  background-position: center top;
  background-repeat: no-repeat;
  color: $primary;
  background-size: 120%;
  width: 100%;
  height: 100vh;
  z-index: -100;
}


//----------- Responsiveness ----------- //
@media (max-width: 1199px) {
  .steps-text-1 {
    left: 40% !important;
    width: 80%;
  }
  .steps-text-2 {
    left: 20% !important;
  }
  .steps-text-3 {
    right: 10%;
    left: 0% !important
  }
  .steps-text-4 {
    padding: 0px !important;
    width: 80%;
    right: 15% !important;
  }
  //--- Page 2
  .mes {
    padding: 0px !important;
  }
  .margin-side-right {
    margin-left: -65px  !important;
  }
  .page2-side-row {
    margin-top: 53px !important;
    margin-left: 92px !important;
  }
  .page2-side-row-end {
    margin-top: -562px !important;
    margin-left: 762px !important;
  }
  //--- Page 4
  .professionals-container .step4 .diagram {
    padding: 20px;
  }
}

.clinical-evidence-scan {
  width: 100%;
}

@media (max-width: 1179px) {
  //--- Page 4
  .professionals-container .step4 .diagram1 .diagram-caption .diagram-caption-1 {
    padding-top: 236px;
  }
  .professionals-container .step4 .diagram1 .diagram-caption .diagram-caption-2 {
    padding-top: 81px;
  }
}

@media (max-width: 1024px) {
  //--- Page 2
  .page2-diagram-lg {
    display: none !important;
  }
  .page2-diagram-sm {
    display: block !important;
    width: 80%;
    height: 2000px;
    margin-left: auto;
    margin-right: auto;
  }
  .step-content {
    padding-bottom: 700px  !important;
  }
}

@media (max-width: 991px) {
  .steps-text-1 {
    bottom: 220px !important;
    left: 40% !important;
    width: 90% !important;
  }
  .steps-text-2 {
    bottom: 250px !important;
    left: 38% !important;
    width: 75% !important;
  }
  .steps-text-3 {
    bottom: 280px !important;
    left: 15% !important;
    right: -20% !important;
  }
  .steps-text-4 {
    right: -5% !important;
  }
  //--- Page 2
  .step-content {
    padding-bottom: 60px !important;
  }
  //--- Page 2
  .professionals-container .step4 .diagram {
    padding: 10px;
  }
  //--- Page 4
  .professionals-container .step4 .diagram1 .diagram-caption {
    font-size: 11px; 
  }
  .professionals-container .step4 .diagram1 .diagram-caption .diagram-caption-1 {
    padding-top: 165px;
  }
  .professionals-container .step4 .diagram1 .diagram-caption .diagram-caption-2 {
    padding-top: 48px;
  }
  .professionals-container .step4 .section2 .score .body img {
    width: 80%;
  }
    .professionals-container .step4 .figure-caption img {
      width: 15px;
    }
    .figure-item {
      font-size: 13px;
      display: flex;
    }
}


@media (max-width: 767px) {
  .professional-background-1 {
    margin-top: 0px;
    top: 0;
  } 
  .section1-image {
    display: none;
  }
    .professionals-container .content2 .sm-clinical-evidence-step h5 {
      font-size: 14px;
      letter-spacing: 0.5px;
      width: 100% !important;
      background-image: linear-gradient(#efeeee, #f4f4f4);
      border-radius: 0.5rem;
      &.selected {
        color: #ffffff;
        background-image: linear-gradient(#f19d57, #e86d03);
      }
    }
  //--- Page 2
  .page2-diagram-sm {
    width: 100%;
    height: inherit;
  }
  .page2-diagram-text {
    margin-left: 10px !important;
  }
  .fai-image {
    width: 80%;
    margin-bottom: 40px;
    margin-left: 38px;
  }
  
  //--- Page 3
  .page3-charts {
    margin-bottom: 40px;
  }
  //--- Page 4
  .professionals-container .step4 .image-section-1 .images {
    display: block;
  }
  .professionals-container .step4 .diagram {
    padding: 30px;
  }
  .professionals-container .margin-addition {
    margin-right: 0px;
    margin-bottom: 45px;
  }
}

@media (max-width: 590px) {
  //--- Page 2
  .page2-diagram-sm {
    width: 100%;
  }
}

@media (max-width: 576px) {
  //--- Page 2
  .page2-diagram-sm {
    width: 100%;
  }
  //--- Page 3
  .professionals-container .step3 .row {
    padding: 40px 0px 0px 0px;
  }
  .page3-charts {
    padding: 0px !important;
  }
}

@media (max-width: 540px) {
  //--- Page 2
  .page2-diagram-sm {
    width: 100%;
  }
}


@media (max-width: 500px) {
  //--- Page 2
  .page2-diagram-sm {
    width: 100%;
  }
}

@media (max-width: 460px) {
  //--- Page 2
  .professionals-container .step4 .diagram {
    padding: 10px;
  }
}
