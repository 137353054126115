.publications-container {
  .page-title {
    font-family: 'Calibri Bold';
    font-size: 28px;
    margin-bottom: 30px;
  }

  .post-list {
    .post-item {
      margin-bottom: 80px;
      &__date {
        font-family: 'Calibri';
        font-size: 15px;
        color: $primary;
        padding-bottom: 10px;
        letter-spacing: 0.5px;
      }

      &__title {
        font-family: 'Calibri Bold';
        font-size: 18px;
        display: block;
        margin-bottom: 12px;
        letter-spacing: 0.5px;
      }

      &__description {
        font-family: 'Calibri Light';
        font-size: 16px;
        letter-spacing: 0.5px;
        margin-bottom: 2em;
        line-height: 1.5;
      }
    }
  }
}
