.card-container {
  box-shadow: 1px 1px 5px 1px #dfdfdf;
  border-radius: 0.5rem;
  font-size: 1.2em;
  padding: 8px 15px;
  font-family: 'Calibri Regular';
  color: $primary;
  background-color: white;
  letter-spacing: 0.5px;
  margin-bottom: 18px;
  margin-top: 18px;
  z-index: 1;
  position: relative;
  width: 300px;
}

//----------- Responsiveness ----------- //
@media (max-width: 991px) {
  .card-container {
    width: 220px;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

@media (max-width: 767px) {
  .card-container {
    width: 100%;
    font-size: 12px;
    margin: 5px;
    display: inline-flex;
    justify-content: center;
  }
}
