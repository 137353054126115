.archive-container {
  padding: 10px 30px;
  margin-top: 80px;
  margin-left: 70px;
  border-left: 1px solid #e0dede;
  .archive-title {
    font-family: 'Calibri Bold';
    color: $blue;
    font-size: 15px;
    letter-spacing: 0.5px;
    margin-bottom: 20px !important;
  }

  .archive-posts__item {
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 30px;
    .item-date {
      font-size: 12px;
      font-family: 'Calibri';
      color: $primary;
      line-height: 2;
      letter-spacing: 0.5px;
    }
    .item-title {
      font-family: 'Calibri Light';
      font-size: 13px;
      line-height: 1.5;
    }
  }
}

//----------- Responsiveness ----------- //
@media (max-width: 767px) {
  .archive-container {
    margin-left: 20px;
  }
}
