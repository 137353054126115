.about-container {
  position: relative;
  min-height: 20em;
  .title {
    font-size: 15px;
  }
  .sub-title {
    font-size: 13px;
    margin-bottom: 25px;
  }
  &.company-history {
    .caption {
      padding: 2em;
      color: $primary;
      text-align: center;
      font-weight: bold;
      font-family: 'Calibri Light';
      letter-spacing: 0.5px;
      font-size: 17px;
    }

    .content1 {
      +.title {
        padding-top: 2em;
        padding-bottom: 1em;
      }
    }



    .team {
        .hover {
          background-color: rgba(#F5A26C, 1);
          color: white;
          width: 100%;
          height: 100%;
          text-align: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          padding: 2em;
          .title {
            font-size: 17px;
            margin-bottom: 5px;
          }
          .sub-title {
            font-size: 13px;
            margin-bottom: 1em;
          }
          .paragraph {
            font-size: 11.5px;
            color: white;
          }
        }
      }
    }
  }

  .management-pictures {
    padding: 30px 30px 0px;
    height: 400px;
    display: inline-block;
  }

//----------- Responsiveness ----------- //
@media (max-width: 1200px) {
  .management-pictures {
    padding: 10px 10px 0px;
    height: 360px;
  }
  .about-container.company-history .team .hover .title {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .about-container.company-history .team .hover .paragraph {
    line-height: 1.2;
  }
  .about-container.company-history .team .hover .sub-title {
    margin-bottom: 0.5em;
  }
}

@media (max-width: 991px) {
  .management-pictures {
    height: 400px;
  }
  .about-container.company-history .team .hover .paragraph {
    line-height: 1.7;
  }
  .about-container.company-history .team .hover .title {
    font-size: 17px;
  }
  .about-container.company-history .team .hover .sub-title {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .management-pictures {
    height: 630px;
  }
  .about-container.company-history .team .hover .paragraph {
    line-height: 1.8;
    font-size: 18px;
  }
  .about-container.company-history .team .hover .sub-title {
    font-size: 20px;
    margin-bottom: 1.5em;
  }
  .about-container.company-history .team .hover .title {
    font-size: 26px;
  }
}

@media (max-width: 591px) {
  .management-pictures {
    height: 500px;
  }
  .about-container.company-history .team .hover .paragraph {
    line-height: 1.5;
    font-size: 15px;
  }
  .about-container.company-history .team .hover .title {
    font-size: 20px;
  }
  .about-container.company-history .team .hover .sub-title {
    font-size: 17px;
    margin-bottom: 1em;
  }
}

@media (max-width: 450px) {
  .management-pictures {
    height: 443px;
  }
  .about-container.company-history .team .hover .title {
    font-size: 17px;
  }
  .about-container.company-history .team .hover .paragraph {
    line-height: 1;
    font-size: 15px;
  }
}

@media (max-width: 360px) {
  .management-pictures {
    height: 300px;
  }
  .about-container.company-history .team .hover .title {
    font-size: 15px;
  }
  .about-container.company-history .team .hover .sub-title {
    margin-bottom: 4px;
  }
  .about-container.company-history .team .hover .paragraph {
    line-height: 1.3;
    font-size: 10px;
  }
}

