.footer-container {
  background-color: #e2e1e1;
  .footer-legal {
    padding: 6px 0;
    background-color: #ffffff;
    p {
      margin: 0;
      font-size: 9px;
      text-align: center;
      letter-spacing: 0.5px;
      color: #909090;
    }
  }
  .footer-content {
    padding-top: 12px;
    margin-bottom: 22px;
    .footer-item {
      position: relative;
      p {
        margin: 0;
        font-size: 10px;
        letter-spacing: 0.5px;
      }
      b {
        font-size: 12px;
        letter-spacing: 1px;
      }
      img {
        width: 140px;
        height: auto;
        margin-bottom: 5px;
      }
      a {
        display: block;
        font-size: 10px;
        color: #3d3d3d;
        text-decoration: none;
        margin-top: 1em;
        letter-spacing: 0.5px;
        &:hover {
          color: #FFFFFF;
        }
      }
    }
    .footer-item-links {
      margin-top: 10px;
    }
  }

  .footer-badges {
    text-align: right;
    img {
      height: 40px;
      min-width: 50px;
      margin: 1em 0em 1em 1em;
    }
    .footer-ce-img {
      vertical-align: bottom;
      min-width: 30px;
    }
    p {
      margin: 0;
      text-align: left;
      font-size: 14px;
    }
  }
}

.footer-badges-description {
  font-size: 10px;
  letter-spacing: 0.5px;
  text-align: right !important;
  margin-bottom: 10px;
  text-align: center;
}


//----------- Responsiveness ----------- //
@media (max-width: 767px) {
  .footer-container .footer-badges {
    text-align: left;
  }
  .footer-badges-description {
    text-align: left !important;
  }
  .footer-container .footer-content .footer-item {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .footer-container .footer-content .footer-item-links {
    margin-top: 30px;
  }
}