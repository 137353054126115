.patients-container {
  position: relative;
  min-height: 20em;

  &.disease {
    .content2 {
      .image-section {
        padding: 60px 0px 90px 0px;
        .slices {
          position: absolute;
          padding: 0px;
          &.fadein {
            animation: fadeIn linear 1s;
          }
          
          &.fadeout {
            animation: fadeOut linear 1s;
            opacity: 0;
          }
        }

        .image1 {
          height: 28em;
          width: 4em;
          padding-top: 2em;
        }

        .slices_2 {
          .step-item:last-child {
            width: 200px;
          }
        }
      }
      .image3 {
        margin-left: -100px;
        z-index: -1;
      }

      .image-title {
        margin-top: 0px !important;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.5px;
        font-family: 'Calibri Bold';
      }

      .heart-img {
        width: 100%;
        margin-top: -60px;
      }
    }
    .content3 {
      margin-top: 10px;
      & .container {
        margin-bottom: -25px;
        padding: 0px;
      }
      .collapse2 {
        position: relative;
        .background {
          position: absolute;
          transform: rotateX(180deg);
          bottom: 0;
          z-index: -1;
          margin-left: -200px;
        }
      }
    }
  }
  .content2 {
    margin: 0px;
  }
    .image2 {
      width: 100%;
    }

    .left-blue {
      margin-top: 60px;
      width: 56%;
    }

    .heart-sm {
      display: none;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 80px;
    }

  &.ccta {
    .hero-image {
      width: 80%;
      padding: 0px 0px 100px 50px;
    }
    .content3 {
      margin-top: 100px;
      .background {
        position: relative;
        margin-top: -10em;
        margin-bottom: 2em;
        margin-left: -200px;
      }
    }
    .subtitle {
      font-family: 'Calibri Bold';
      font-size: 15px;
      font-weight: 200 !important;
    }
    .list-square {
      list-style-type: square;
      color: $primary;
      margin-block-end: 4em;
      li {
        font-size: 1.3em;
        margin-bottom: 25px;
      }
      p {
        max-width: 80%;
        font-size: 12px !important;
      }
      .paragraph {
        margin-bottom: 0px !important;
      }
      .click-here {
        font-family:  'Calibri Bold';
        text-decoration: none;
        color: $text-color;
      }
    }
    .click-here {
      font-family:  'Calibri Bold';
      text-decoration: none;
      color: $text-color;
    }
  }
}

.sm-image2 {
  display: none;
}
  .sm-slices-list{
    display: none;
  }

.patient-img {
  width: 100%;
}

.ccta-scanner-img {
  width: 100%;
}

//----------- Responsiveness ----------- //
@media (max-width: 991px) {
  //What is Cardiovascular Disease
  .slice-container {
    height: 400px;
  }

  //What is CCTA
  .patients-container.ccta .hero-image {
    width: 100%;
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  //What is Cardiovascular Disease
  .rightlink-container {
    display: none;
  }
  .content2 {
    margin: 0px;
  }
    .patients-container .left-blue {
      display: none;
    }
      .slice-container {
        height: 500px;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }

    .patients-container.disease .content2 .heart-img {
      display: none;
    }
    .heart-sm {
      display: block !important;
      margin-top: -155px;
    } 

  .patient-img {
    width: 120%;
    margin-left: -45px;
    margin-top: 40px;
  }

  //What is CCTA
  .patients-container.ccta .hero-image {
    padding: 30px 0px 50px 0px;
  }
  .ccta-scanner-img {
    width: 90%;
    margin-bottom: 40px;
  }
}

@media (max-width: 591px) {
  .image2 {
    display: none;
  }
  .slices-list {
    display: none !important;
  }
    .sm-image2 {
      display: block;
      width: 100%;
      padding: 0px;
      margin-top: -60px;
    }
    .sm-slices-list{
      display: block;
    }

    .heart-sm {
      margin-top: 55px;
    }
}

@media (max-width: 590px) {
  .heart-sm {
    margin-top: 0px;
  }
}

@media (max-width: 580px) {
  //What is Cardiovascular Disease
    .slice-container {
      height: 400px;
    }
    .patient-img {
      margin-left: 0px;
      width: 100%;
      margin-top: 40px;
    }
      .card-container {
        font-size: 15px;
        text-align: center;
        line-height: 1.2;
      }
      .heart-sm {
        margin-top: 80px;
      }
}  

@media (max-width: 575px) {
  .heart-sm {
    margin-top: 130px;
  }
}

@media (max-width: 532px) {
  //What is Cardiovascular Disease
    .slice-container {
      height: 450px;
    }
    .patients-container.disease .content2 .image-section {
      padding: 30px 0px 0px 0px;
    }
    .slice-container {
      height: 350px;
    }
} 

@media (max-width: 500px) {
  .sm-image2 {
    width: 112%;
  }
}

@media (max-width: 470px) {
  .sm-image2 {
    width: 109%;
    margin-top: -39px;
  }
  .step-text {
    padding-bottom: 50px;
  }
  .heart-sm {
    margin-top: 105px;
    width: 100%;
  }
}


@media (max-width: 450px) {
  .sm-image2 {
    width: 115%;
    margin-left: -41px;
  }
}

@media (max-width: 430px) {
  .sm-image2 {
    width: 125%;
  }
}

@media (max-width: 414px) {
  .sm-image2 {
    width: 129%;
  }
}

@media (max-width: 320px) {
  .heart-sm {
    margin-top: 30px;
  }
  .step-text {
    padding-bottom: 35px;
    font-size: 11px;
  }
}

