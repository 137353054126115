.step-carousel {
  position: relative;
  height: 100%;
  img {
      position: absolute;
      z-index: 0;
      bottom: 0;
  }
  .select {
      z-index: 1;
      height: 90%;
  }

  .step-main-image {
    width: 115%;
    position: absolute;
    left: -100px;
  }
}

.sm-step-carousel {
  position: relative;
  height: 100%;
  padding: 0px;
  margin: 0px;
  img {
      position: absolute;
      z-index: 0;
      bottom: 0;
  }
  .select {
      z-index: 1;
      height: 90%;
  }

  .step-main-image {
    width: 115%;
    position: absolute;
    left: -100px;
  }
}


.sm-clinical-evidence-step {
  display: none;
}

//----------- Responsiveness ----------- //
@media (max-width: 1199px) {
  .select {
    height: 100%;
  }
}

@media (max-width: 991px) {
  .select {
    height: 115% !important;
  }

  .step-main-image {
    width: 120% !important;
    left: -60px !important;
  }
}

@media (max-width: 767px) {
  .sm-step-carousel .select {
    z-index: 0;
  }
  .clinical-evidence-step {
    display: none;
  }
  .sm-clinical-evidence-step {
    display: block;
    margin: 10px 0px 363px 0px !important;
  }
  .sm-step-carousel {
    height: 100%;
  }
  .select {
    height: 0px !important
  }
  .sm-step-carousel img {
    z-index: 0;
    bottom: -360px;
  }
}

@media (max-width: 530px) {
  .sm-step-carousel img {
    bottom: -263px;
  }
  .sm-clinical-evidence-step {
    display: block;
    margin: 10px 0px 275px 0px !important;
  }
}

@media (max-width: 495px) {
  .sm-step-carousel {
    height: 100%;
  }
}

@media (max-width: 465px) {
  .select {
    height: 10px !important
  }
}

@media (max-width: 300px) {
  .sm-step-carousel img {
    bottom: -245px;
  }
}

