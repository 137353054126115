@import 'variables';
@import 'common';

// Components
@import 'components/header';
@import 'components/footer';
@import 'components/breadcrumb';
@import 'components/rightlink';
@import 'components/step';
@import 'components/collapse';
@import 'components/card';
@import 'components/step_carousel';
@import 'components/video';
@import 'components/hover_display';
@import 'components/archive';

// Pages
@import 'pages/home';
@import 'pages/news';
@import 'pages/contact';
@import 'pages/about';
@import 'pages/patients';
@import 'pages/professionals';
@import 'pages/publications';
@import 'pages/product';

body {
  background-color: #ffffff;
  color: #3d3d3d;
  min-height: 100vh;
  margin: 0;

  font-family: 'Calibri Regular';
}

.app-container {
  max-width: 100vw;
}

.base-container {
  min-height: calc(100vh - 10em);
  width: 100%;
}
