.step-container {
  display: flow-root;
  width: 100%;
}
  .step-item {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .step-text {
    font-family: 'Calibri Light';
    color: $text-color;
    font-size: 13px;
    display: inline-block;
    letter-spacing: 0.5px;
    text-align: center;
    width: 17% !important;
  }
  .step-arrow-col {
    width: 3% !important;
    padding: 0px !important;
  }
    .step-arrow {
      width: 50%;
    }

//----------- Responsiveness ----------- //
@media (max-width: 991px) {

}

@media (max-width: 591px) {
  .step-container {
    display: inline-grid;
    width: 100%;
  }
  .step-sm-row {
    width: 100%;
  }
    .step-text {
      width: 50% !important;
      text-align: left;
      padding-bottom: 70px;
      font-size: 13px;
    }
      .step-arrow-col {
        width: 30% !important;
      }
      .step-arrow {
        width: 100%;
        transform: rotate(90deg);
        margin-top: -5px;
        margin-left: -35px;
      }
}

@media (max-width: 590px) {
  .step-text {
    padding-bottom: 52px;
  }
}

@media (max-width: 575px) {
  .step-text {
    padding-bottom: 60px;
  }
  .step-arrow {
    width: 30%;
    margin-left: -186%;
  }
}

@media (max-width: 558px) {
  .step-text {
    padding-bottom: 54px;
  }
}