$primary: #f08a54;
$primary-hover: #f39f73;
$blue: #344e79;
$grey-100: #3d3d3b;
$text-color: #3d3d3b;

@font-face {
  font-family: 'iCiel Gotham Medium';
  src: url('../fonts/iciel-gotham-medium.ttf');
}

@font-face {
  font-family: 'iCiel Gotham Light';
  src: url('../fonts/iciel-gotham-thin.ttf');
}

@font-face {
  font-family: 'Calibri Regular';
  src: url('../fonts/calibri.ttf');
}

@font-face {
  font-family: 'Calibri Light';
  src: url('../fonts/calibril.ttf');
}

@font-face {
  font-family: 'Calibri Bold';
  src: url('../fonts/calibrib.ttf');
}

@font-face {
  font-family: 'Calibri Italic';
  src: url('../fonts/calibrili.ttf');
}

