.page-title {
  color: $blue;
  font-family: 'iCiel Gotham Medium';
  font-size: 28px;
  line-height: 1.5;
  margin-bottom: 1em;
  letter-spacing: 0.8px;
}

.paragraph {
  margin-bottom: 2em;
  color: $grey-100;
  font-family: 'iCiel Gotham Light';
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 2;
  font-weight: 700;
}

.title {
  color: $grey-100;
  font-family: 'iCiel Gotham Medium';
  font-size: 1.4em;
}

.sub-title {
  font-family: 'iCiel Gotham Medium';
  font-size: 1.2em;
}

.link {
  text-decoration: none;
  color: $text-color;
  &:hover {
    color: $primary-hover;
    text-decoration: none;
  }
}

.page-content-container {
  padding-top: 30px;
  padding-bottom: 150px;
}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

//----------- Responsiveness ----------- //
@media (max-width: 767px) {
  .page-content-container  {
    padding-top: 0px;
  }
}
