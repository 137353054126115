// Bootstrap amends
.navbar {
  margin-bottom: 0px !important;
  min-height: 40px !important;
  height: 40px !important;
}
  .navbar-nav {
    height: 40px !important;
  }

  .nav-item {
    color: rgba(0,0,0,.5) !important;
    text-decoration: none;
  }

  .navbar-light .navbar-nav .nav-link:hover {
    background: #e2e1e1 !important;
  }

  .nav-link {
    font-family: 'Calibri Light';
    position: relative;
    padding: 0 4em;
    text-align: center;
    color: #4A4A49;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
    letter-spacing: 0.8px;
    padding: 10px 40px !important;
  }
    .dropdown-toggle::after {
      display: none !important;
    }

    .navbar-nav .dropdown-menu {
      width: 250px;
      margin-top: 0px !important;
      border: none !important;
      border-radius: 0px !important;
      font-family: 'Calibri Light';
      letter-spacing: 0.5px;
    }

    .dropdown-item {
      padding: 0.8rem 1.8rem;
    }

a:hover, a:visited, a:link, a:active{
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #ffa778 !important;
}

.dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover {
  background-color: #ffa778 !important;
}

// Navbar styling
.nb-navbar {
  background-color: #ffffff;
  box-shadow: 1px 1px 10px 0px lightgray;
  height: 40px !important;
}
  .nb-dropdown-item {
    color: #3D3D3B;
    text-decoration: none;
  }

.nb-social-icons {
  float: right;
  margin-left: 120px;
  padding-top: 7px;
}
  .nb-twitter {
    width: 25px;
    z-index: 1;
    position: absolute;
    margin-top: 1px;
  }

  .nb-linkedin {
    width: 25px;
    z-index: 1;
    position: absolute;
    margin-top: -1px;
    margin-left: 30px;
  }
  .nb-instagram {
    width: 22px;
    position: relative;
    margin-top: 1.5px;
    margin-right: 0px;
    margin-left: 62px;
  }

.no-margin {
  margin: 0px !important;
}

.navbar-container {
  margin: 0px!important;
  border-top: 5px solid #eeeeee;
  .top-header {
    // height: 150px;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .nav-brand {
      img {
        height: 60px;
        margin-left: 20px;
      }
    }

    .contact-header-btn {
      font-family: 'Calibri Light';
      width: 80%;
      float: right;
      font-size: 16px;
      background: $primary;
      border: none;
      border-radius: 25px;
      color: white;
      text-align: center;
      letter-spacing: 0.5px;
      margin-top: -30px;
      &:hover {
        background: $primary-hover;
      }
    }
  }
  
  .nav-content {
    line-height: 3em;
    height: 44px;
    background-color: #ffffff;
    box-shadow: 1px 1px 10px 0px lightgray;
    .container {
      &::before, &::after {
        content: none;
      }
      display: flex;
      justify-content: space-between;
    }
    .nav-content-links {
      display: flex;
      height: 44px;
      .nav-item {
        font-family: 'Calibri Light';
        position: relative;
        padding: 0 4em;
        height: 44px;
        line-height: 43px;
        text-align: center;
        color: #4A4A49;
        text-decoration: none;
        font-size: 14px;
        cursor: pointer;
        letter-spacing: 0.8px;
        &:hover {
          z-index: 1000;
          background-color: #E2E1E1;
          .nav-content-sub-links {
            position: absolute;
            display: block;
            top: 50px;
          }
        }
      }
    }
    .nav-content-social {
      display: flex;
      justify-content: flex-end;
      img {
        width: 2em;
        &:last-child {
          margin-left: 1em;
        }
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .nav-content-sub-links {
      display: none;
      min-width: 100%;
      padding: 1em 3em;
      position: relative;
      top: 0px;
      left: 0;
      font-size: 85%;
      box-shadow: 1px 1px 10px 0px lightgray;
      background-color: #ffffff;
      margin-top: -7px;
      .nav-item__sub-item {
        white-space: nowrap;
        display: block;
        color: #3D3D3B;
        text-decoration: none;
        font-size: 1.2em;
        text-align: left;
        // height: 40px;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.nav-item:hover .dropdown-menu{
  display:block !important;
}

.contact-btn {
  display: none;
}



//----------- Responsiveness ----------- //
@media (max-width: 1999px) {
  .home-section .hero-sub-title {
    line-height: 1.5 !important;
  }
  .nb-social-icons {
    margin-left: 30px !important;
  }
}

@media (max-width: 991px) {
  .navbar-light .navbar-toggler {
    display: none;
  }
  .navbar-nav {
    flex-direction: row;
    margin: -10px !important;
  }
  .nav-link {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .nav-item {
    width: 130px;
  }

}

@media (max-width: 850px) {
  .nb-social-icons {
    margin-left: 0px !important;
  }
}

@media (max-width: 767px) {
  .navbar-light .navbar-toggler {
    display: block;
  }
  .navbar-container .top-header {
    justify-content: center;
  }
  .navbar-nav {
    margin-left: 0px !important;
    margin-right: 0px !important;
    flex-direction: column;
    margin: 0px !important;
  }
  .navbar-collapse {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background: #FFFFFF;
  }
  .navbar-container .top-header .nav-brand img {
    height: 90px;
    margin-left: 0px;
  }
    .nb-navbar {
      background: none;
      box-shadow: none;
      height: 0px;
    }
    .navbar {
      position: fixed !important;
      z-index: 1;
      left: 0;
      padding: 0px;
      width: 100%;
      height: inherit;
    }
    .navbar-light .navbar-toggler {
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 100px;
      padding-top: 3px;
      top: 15px;
      left: 15px;
      z-index: 2;
      background: $primary;
      border: none;
      box-shadow: 2px 2px 3px 0px lightgrey;
    }
    .nav-item {
      width: 100%;
    }
    .show {
      width: 100%;
      height: 100%;
      background: #FFFFFF;
    }
      .nav-link {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 20px;
        padding-left: 0px !important;
        padding-right: 0px !important;
        transition: 0.5s;
        background: #FFFFFF;
      }
        .navbar-nav .dropdown-menu {
          width: 100%;
          box-shadow: none;
          text-align: center;
          font-size: 16px;
          background: $primary;
          margin-top: 0px !important;
          padding: 0px;
        }
        .nb-dropdown-item {
          color: #FFFFFF !important;
        }
        .dropdown-item {
          padding: 15px 0px 15px 0px;
          border-bottom: 1px solid #f9a67a;
          background: $primary;
          letter-spacing: 0.8px;
          font-size: 18px;
          &:hover {
            background: #f9a67a;
          }
        }

      .nb-social-icons {
        text-align: center;
        float: none;
        margin-bottom: 40px;
        margin-left: 0px !important;

      }
        .nb-twitter {
          width: 35px;
          position: relative;
          margin-top: 0px;
        }
        .nb-linkedin {
          width: 35px;
          position: relative;
          margin-right: 10px;
          margin-left: 10px;
        }
        .nb-instagram {
          width: 30px;
          position: relative;
          margin-right: 0px;
          margin-left: 0px;
        }

  .contact-btn {
    display: block;
  }

  .contact-header-container {
    display: none;
  }
        
}

