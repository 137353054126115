.hover-display {
  position: relative;
  width: 100%;
  height: 100%;
  
  .background {
    width: 100%;
    height: 100%;
  }
  
  .foreground {
    position: absolute;
    display: none;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
