.pab-pill {
    background-color: rgba(#F5A26C, 1);
    color: white;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 15px;
    cursor: pointer;
        .title {
            font-size: 18px;
        }
    }
    .pab-pill:hover {
        opacity: 0.5;
    }