.rightlink-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  .rightlink-items {
    width: 300px;
    display: inline-block;
  }

  .name {
    font-family: 'Calibri Bold';
    color: $blue;
    font-size: 16px;
    padding: 0 10px;
    margin-bottom: 15px;
    letter-spacing: 1.5px;
  }
  .link {
    text-decoration: none;
    border-radius: 0.5rem;
    margin-bottom: 1em;
    padding: 20px;
    font-size: 16px;
    font-family: 'Calibri Light';
    word-break: break-word;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(#efeeee, #f4f4f4);
    color: #3d3d3b;
    letter-spacing: 0.5px;
    &:hover {
      opacity: 0.5;
    }
    &.selected {
      color: #ffffff;
      background-image: linear-gradient(#f19d57, #e86d03);
    }
    img {
      height: 20px;
    }
  }
}
